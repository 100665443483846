<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { evaPerson } from '@quasar/extras/eva-icons'
import type { StakeAccount, ValidatorData } from '~/store'
import { formatAmount, lamportsToSol, shortenAddress } from '~/utils'

const props = defineProps({
  loading: Boolean,
  stakeAccount: {
    type: Object as () => StakeAccount,
    required: true,
  },
  validator: {
    type: Object as () => ValidatorData,
  },
})

const emit = defineEmits(['depositJpool'])

const shortAddress = computed(() => shortenAddress(props.validator?.voteId ?? '', 7))

const amount = computed(() => {
  const num = Number(lamportsToSol(props.stakeAccount?.account?.lamports ?? 0))
  return num >= 0 ? formatAmount(num, num < 1 ? 5 : 3) : ''
})

function depositJpool() {
  emit('depositJpool', props.stakeAccount)
}
</script>

<template>
  <div v-if="validator && validator.inJpool" class="redelegation__stake row items-center">
    <div class="redelegation__stake__validator">
      <div class="row justify-center q-mr-md">
        <q-avatar class="shadow-1" size="50px">
          <q-img :src="validator.image" spinner-size="30px" spinner-color="light-gray">
            <template v-if="!validator.image" #default>
              <q-icon :name="evaPerson" />
            </template>
            <template #error>
              <q-icon :name="evaPerson" />
            </template>
          </q-img>
        </q-avatar>
      </div>
      <div class="column">
        <div class="redelegation__stake__validator__name q-pt-xs">
          {{ validator.name ? validator.name : shortAddress }}
          <q-tooltip anchor="bottom middle">
            <div class="text-body1 break-words">
              {{ $t('validators.table.tooltip.name') }}: {{ validator.name ? validator.name : validator.voteId }}
            </div>
          </q-tooltip>
        </div>

        <div class="redelegation__stake__validator__address">
          <span>
            {{ shortAddress }}
            <q-tooltip anchor="bottom middle" class="text-body2 break-words">
              {{ validator.voteId }}
            </q-tooltip>
          </span>
        </div>
      </div>
    </div>

    <div class="redelegation__stake__amount">
      {{ amount }} SOL
    </div>

    <q-btn class="redelegation__stake__btn" unelevated color="secondary" text-color="black" @click="depositJpool">
      {{ $t('smartStaking.redelegation.btn') }}
    </q-btn>

    <q-inner-loading :showing="loading">
      <q-spinner-gears size="48px" color="warning" />
    </q-inner-loading>
  </div>
</template>
